<div
    [class]="{
        tooltip: true,
        'display-left': tooltipService.leftDisplay()
    }"
    [attr.show]="tooltipService.show() ? true : null"
    [ngStyle]="{
        top: tooltipService.position()['top'],
        left: tooltipService.position()['left']
    }">
    <div class="arrow"></div>

    @for (row of tooltipService.content().rows; track row.label) {
        <div class="row">
            <div class="label">
                <span
                    class="color"
                    [ngStyle]="{ background: row.color }"></span>
                <span class="name">{{ row.label }}</span>
            </div>
            <div class="percentage">{{ row.percentage }}</div>
            <div class="value">{{ row.value }}</div>
        </div>
    }
</div>
