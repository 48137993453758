import { CommonModule } from '@angular/common';
import { TooltipService } from '@services/tooltip/tooltip.service';
import { Component, ElementRef, ViewChild, inject } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './tooltip.component.html',
    styleUrl: './tooltip.component.scss',
})
export class TooltipComponent {
    @ViewChild('tooltipRef') tooltipRef!: ElementRef;
    tooltipService: TooltipService = inject(TooltipService);
}
